<template>
  <div class="my-courses app-my-wrap">
    <div class="app-my-title">
      講座受講一覧
    </div>
    <!-- <div class="my-courses-filters">
      <span class="my-courses-filters-label">ステータス: </span>
      <el-select v-model="status">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div> -->
    <div class="app-my-content">
      <div class="my-courses-table-wrap">
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="showid"
            label="講座ID"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="受講ステータス"
            width="120"
          >
            <template slot-scope="scope">
              {{statusOptionsObj[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column
            prop="range"
            label="講座公開期間"
            width="220"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="講座名"
          >
            <template slot-scope="scope">
              <router-link class="app-header-logo" :to="`/course/${scope.row.id}`">
                {{scope.row.name}}
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import constant from '@/utils/constant';

const { myCourseStatusOptions } = constant;

export default {
  data() {
    return {
      status: '0',
      statusOptions: myCourseStatusOptions,
      statusOptionsObj: myCourseStatusOptions.reduce((total, current) => {
        const temp = total;
        temp[current.value] = current.label;
        return temp;
      }, {}),
      tableData: [],
    };
  },
  created() {
    request('getMyCourseList').then((res) => {
      this.tableData = res.myPageResponseResourceList.map((item) => ({
        showid: `No.${item.courseId}`,
        id: item.courseId,
        name: item.courseTitle,
        status: item.studyStatus,
        range: this.formatRange(item.openStartTime, item.openEndTime),
      }));
    });
  },
  methods: {
    formatNull(data) {
      if (!data || data === 'null') return '';
      return data;
    },
    formatRange(start, end) {
      if (start === 'null' && end === 'null') {
        return '';
      }
      return `${this.formatNull(start)}~${this.formatNull(end)}`;
    },
  },
  // mounted() {
  //   console.log(this.statusOptionsObj);
  // },
};
</script>

<style lang="less" scoped>
.my-courses {
  .my-courses-filters {
    margin-bottom: 20px;

    .my-courses-filters-label {
      margin-right: 10px;
    }
  }
}
</style>
